<template>
    <section id="costs">
        <v-container class="pt-2">
            <v-row class="row main_about text-center pb-5">
                <v-col cols="12">
                    <div class="reg_container">
                        <v-select
                            v-model="region"
                            class="reg_input"
                            color="#8b1103"
                            item-text="value"
                            item-value="id"
                            :items="items"
                            label="Регион"
                            solo
                        />
                        <p>Выбрать<br>регион</p>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="costs_body">
                        <transition name="swing">
                            <div
                                v-if="showAnim"
                                class="cost_item"
                            >
                                <b>ДТ</b><br>{{ cost.adt }} р.
                            </div>
                        </transition>
                        <transition name="swing">
                            <div
                                v-if="showAnim"
                                class="cost_item"
                            >
                                АИ-<b>92</b><br>{{ cost.a92 }} р.
                            </div>
                        </transition>
                        <transition name="swing">
                            <div
                                v-if="showAnim"
                                class="cost_item"
                            >
                                АИ-<b>95</b><br>{{ cost.a95 }} р.
                            </div>
                        </transition>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    name: "HomeCosts",
    data() {
        return {
            region: 1,
            showAnim: true,
            items: [
                {"id": 1, "value": "Москва и МО"},
                {"id": 2, "value": "Краснодарский край"}
            ]
        }
    },
    computed: {
        costs() {
            return this.$store.getters.getCosts
        },
        cost() {
            if (this.region === 1) {
                if (this.costs !== null) {
                    return this.costs.moscow
                } else {
                    return { "a92": "00", "a95": "00", "adt": "00"}
                }
            } else {
                if (this.costs !== null) {
                    return this.costs.rostov
                } else {
                    return { "a92": "00", "a95": "00", "adt": "00"}
                }
            }
        }
    },
    watch: {
        region () {
            this.showAnim = false
            setTimeout(()=>{
                this.showAnim = true
            },100)
        }
    }
}
</script>

<style>
    .swing-enter-active {
        animation: swing .5s;
    }
    #costs {
        padding: 25px 0 50px 0;
    }
    .reg_container {
        max-width: 350px;
        margin: 0 auto;
        display: flex;
    }
    .reg_container p {
        margin: 10px 0 0 10px;
        font-size: 12px;
        line-height: 12px;
        text-align: left;
    }
    .reg_input {

    }
    .reg_input .v-select__selection--comma {
        font-size: 20px;
        line-height: 23px;
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        color: #8b1103 !important;
    }
    .costs_body {
        max-width: 600px;
        height: 140px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
    }
    .cost_item {
        padding: 22px 0;
        border: 10px solid transparent;
        background:
            linear-gradient(#fff,#fff) padding-box, /*this is your grey background*/
            linear-gradient(45deg, #9B1208, #BC542D) border-box;
        border-radius: 70px;
        width: 140px;
        height: 140px;
        font-size: 24px;
        text-align: center;
    }

    @media (max-width: 550px) {
        .costs_body {
            height: 90px;
        }
        .cost_item {
            padding: 14px 0;
            border: 6px solid transparent;
            border-radius: 45px;
            width: 90px;
            height: 90px;
            font-size: 16px;
        }
    }
</style>
