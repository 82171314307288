<template>
    <div>
        <v-navigation-drawer
            v-model="drawer"
            app
            temporary
        >
            <v-list dense>
                <v-subheader class="drawer_header">МЕНЮ</v-subheader>
                <v-btn
                    class="drawer_close"
                    icon
                    @click="drawer=false"
                >
                    <v-icon>fa-times</v-icon>
                </v-btn>
                <v-list-item-group color="primary">
                    <v-list-item
                        class="drawer_phone"
                        href="tel:+79999911911"
                    >
                        <v-list-item-icon>
                            <v-icon>fa-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>+7 9999 911 911</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        class="drawer_callback"
                        @click="openCallBack"
                    >
                        <v-list-item-icon>
                            <v-icon>fa-phone-alt</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>ОБРАТНЫЙ ЗВОНОК</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="drawer_divider" />
                    <v-list-item to="/">
                        <v-list-item-icon>
                            <v-icon>fa-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>ГЛАВНАЯ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item to="/faq/">
                        <v-list-item-icon>
                            <v-icon>fa-question-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>F.A.Q.</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item to="/contacts/">
                        <v-list-item-icon>
                            <v-icon>fa-map-marker-alt</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>КОНТАКТЫ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="userLogin === 'artem'" />
                    <v-list-item
                        v-if="userLogin === 'artem'"
                        to="/public/"
                    >
                        <v-list-item-icon>
                            <v-icon>fa-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>ПРЕЗЕНТАЦИИ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="isUserLoggedIn" />
                    <v-list-item
                        v-if="isUserLoggedIn"
                        to="/lk/"
                    >
                        <v-list-item-icon>
                            <v-icon>fa-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>ЛИЧНЫЙ КАБИНЕТ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="!isUserLoggedIn" />
                    <v-list-item
                        v-if="!isUserLoggedIn"
                        @click="openLoginPopup"
                    >
                        <v-list-item-icon>
                            <v-icon>fa-user-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>ВХОД</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="isUserLoggedIn" />
                    <v-list-item
                        v-if="isUserLoggedIn"
                        @click="openUserPopup"
                    >
                        <v-list-item-icon>
                            <v-avatar
                                class="mr-2"
                                color="error"
                                size="24px"
                            >
                                <span class="white--text headline">{{ user.login.substr(0, 1).toUpperCase() }}</span>
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ user.login }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar

            app

        >
            <v-app-bar-nav-icon
                class="hidden-md-and-up"
                @click="drawer = !drawer"
            />

            <v-toolbar-title>
                <router-link
                    class="pointer"
                    to="/"
                >
                    <img
                        alt="Refuel black logo"
                        class="ref_logo"
                        src="../assets/images/logo_black.png"
                    >
                </router-link>
            </v-toolbar-title>

            <v-spacer />

            <div class="block_head_phone">
                <a
                    class="black_phone"
                    href="tel:+79999911911"
                >+7 9999 911 911</a>
                <a
                    class="call_back"
                    @click="openCallBack"
                ><span>перезвонить вам?</span></a>
            </div>

            <v-spacer />

            <v-toolbar-items class="hidden-sm-and-down">
                <v-btn to="/">
                    ГЛАВНАЯ
                </v-btn>
                <v-btn to="/faq/">
                    F.A.Q.
                </v-btn>
                <v-btn to="/contacts/">
                    КОНТАКТЫ
                </v-btn>
                <v-btn
                    v-if="userLogin === 'artem'"
                    to="/public/"
                >
                    ПРЕЗЕНТАЦИИ
                </v-btn>
                <v-btn
                    v-if="isUserLoggedIn"
                    to="/lk/"
                >
                    ЛИЧНЫЙ КАБИНЕТ
                </v-btn>
                <v-btn
                    v-if="!isUserLoggedIn"
                    @click="openLoginPopup"
                >
                    <v-icon
                        class="mr-2"
                        color="grey darken-1"
                    >
                        fa-user-circle
                    </v-icon>
                    ВХОД
                </v-btn>
                <v-btn
                    v-if="isUserLoggedIn"
                    @click="openUserPopup"
                >
                    <v-avatar
                        class="mr-2"
                        color="error"
                        size="36px"
                    >
                        <span class="white--text headline">{{ user.login.substr(0, 1).toUpperCase() }}</span>
                    </v-avatar>
                    {{ user.login }}
                </v-btn>
            </v-toolbar-items>
        </v-app-bar>

        <div class="header-helper" />
    </div>
</template>

<script>
export default {
    name: "SiteHeader",
    data () {
        return {
            drawer: false,
            test: true,
            item: 0,
        }
    },
    computed: {
        user () {
            return this.$store.getters.user
        },
        isUserLoggedIn () {
            return this.$store.getters.isUserLoggedIn
        },
        userLogin () {
            return this.$store.getters.getUserLogin
        }
    },
    methods: {
        openLoginPopup () {
            this.$emit('loginPopupChanged', true)
        },
        openUserPopup () {
            this.$emit('userPopupChanged', true)
        },
        openCallBack () {
            this.$store.dispatch('showCallBackPopup')
        },
        handleScroll: function () {
            if (window.scrollY > 0) {
                document.querySelector('header .v-toolbar__content').style.height= '48px'
                document.querySelector('header.v-toolbar').style.height= '48px'
                document.querySelector('.header-helper').style.height= '48px'
            }
            else {
                document.querySelector('header .v-toolbar__content').style.height= '64px'
                document.querySelector('header.v-toolbar').style.height= '64px'
                document.querySelector('.header-helper').style.height= '64px'
            }
        }
    }
}
</script>

<style>
    header .v-toolbar__content {

        margin: 0 auto;
    }

    .block_head_phone {
        margin: 0;
    }

    .block_head_phone a {
        display: block;
    }

    .block_head_phone a:first-child {
        color: #8b1103;
        font-size: 24px;
        line-height: 20px;
        font-weight: 400;
        text-decoration: none;
    }
    .block_head_phone a:first-child:hover {
        text-shadow: 0.5px 0.5px #a1a1a1;
    }

    .block_head_phone a:last-child {
        color: #a1a1a1;
        font-size: 13px;
        line-height: 10px;
        text-align: right;
        margin-top: 3px;
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        font-weight: 600;
    }
    .block_head_phone a:last-child:hover {
        color: #7D7D7D;
    }
    .block_head_phone a:last-child span {
        border-bottom: 1px dotted #a1a1a1;
    }
    .block_head_phone a:last-child:hover span {
        border-bottom: 1px solid transparent;
    }

    header .v-toolbar__content .v-btn--active {
        color: #8b1103;
    }

    header .v-toolbar__content .v-btn--active:after {
        content: " ";
        width: 100%;
        height: 3px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #8b1103;
    }

    header .v-toolbar__content .v-btn--active:before {
        background-color: rgba(0,0,0,.4);
    }

    .header-helper {
        width: 100%;
        display: flex;
        transition: all 0.3s ease-out;
        height: 64px;
    }

    .drawer_close {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .v-list--dense .v-subheader.drawer_header {
        font-size: 1.25rem;
        padding: 0 0 0 75px;
    }

    .drawer_phone,
    .drawer_callback,
    .drawer_divider {
        display: none;
    }


    @media (max-width: 960px) {
        .header-helper {
            height: 56px;
        }
    }

    @media (max-width: 580px) {
        .block_head_phone {
            display: none;
        }

        .drawer_phone,
        .drawer_callback,
        .drawer_divider {
            display: flex;
        }
    }

    @media (max-width: 380px) {
        .ref_logo {
            width: 200px;
        }
    }
</style>
