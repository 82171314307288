<template>
    <section id="partners">
        <v-container>
            <h2 class="d-block text-center refuel_red py-0">
                Наши Партнёры
            </h2>
            <v-row class="py-3 py-md-5">
                <v-col
                    class="pb-0 pb-md-5"
                    cols="4"
                >
                    <img
                        alt="Газпром Нефть - партнер нашей компании Refuel"
                        src="../assets/images/gazprom.png"
                    >
                </v-col>
                <v-col
                    class="pb-0 pb-md-5"
                    cols="4"
                >
                    <img
                        alt="Лукойл - партнер компании Refuel"
                        src="../assets/images/lukoil.png"
                    >
                </v-col>
                <v-col
                    class="pb-0 pb-md-5"
                    cols="4"
                >
                    <img
                        alt="Роснефть наш партнер"
                        src="../assets/images/rosneft.png"
                    >
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    name: "HomePartners"
}
</script>

<style scoped>
    #partners {
        background-color: #F0F0F0;
    }

    #partners h2 {
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        font-size: 48px;
        line-height: normal;
        margin: 0 auto;
        font-weight: 500;
    }

    #partners p {
        font-size: 18px;
        line-height: normal;
        color: #555;
    }

    #partners .row div {
        text-align: center;
    }

    #partners .row img {
        display: inline-block;
        margin: 0 auto;
    }

    @media (max-width: 991px) {
        #partners .row img {
            width: 100%;
        }
    }

    @media (max-width: 576px) {
        #partners h2 {
            font-size: 36px;
        }

        #partners p {
            font-size: 16px;
        }
    }

    @media (max-width: 340px) {
        #partners h2 {
            font-size: 32px;
        }

        #partners p {
            font-size: 15px;
        }
    }
</style>
