<template>
    <section id="privileges">
        <div class="privileges_tooth_up" />
        <v-container>
            <h2 class="d-block text-center refuel_red pb-1 pt-5">
                Наши Преимущества
            </h2>
            <p class="d-block text-center mb-3 mb-lg-5">
                Отличный сервис, качественное топливо и своевременная заправка Вашего транспорта.
            </p>
            <div class="privileges_row pb-3 pb-lg-5">
                <div class="privileges_col">
                    <img
                        alt=""
                        src="../assets/images/privilege_1.png"
                    >
                    <p>Мы работаем напрямую с заводами производителями</p>
                </div>
                <div class="privileges_col">
                    <img
                        alt=""
                        src="../assets/images/privilege_2.png"
                    >
                    <p>Сервис и техническая поддержка</p>
                </div>
                <div class="privileges_col">
                    <img
                        alt=""
                        src="../assets/images/privilege_3.png"
                    >
                    <p>Проводим анализ качества и экспресс тест топлива</p>
                </div>
                <div class="privileges_col">
                    <img
                        alt=""
                        src="../assets/images/privilege_4.png"
                    >
                    <p>Наличие тех помощи в дороге, а именно замена колес, ремонт резины, эвакуация, буксировка</p>
                </div>
                <div class="privileges_col">
                    <img
                        alt=""
                        src="../assets/images/privilege_5.png"
                    >
                    <p>Мобильность</p>
                </div>
                <div class="privileges_col">
                    <img
                        alt=""
                        src="../assets/images/privilege_6.png"
                    >
                    <p>Видеофиксация, пломбирование и полная автоматизация системы позволяют исключить случаи хищения топлива, благодаря чему достигается существенная экономия – до 20%. </p>
                </div>
                <div class="privileges_col">
                    <img
                        alt=""
                        src="../assets/images/privilege_7.png"
                    >
                    <p>Экономия вашего времени и денег</p>
                </div>
            </div>
        </v-container>
        <div class="privileges_tooth_down" />
    </section>
</template>

<script>
export default {
    name: "HomePrivileges"
}
</script>

<style scoped>
    #privileges {
        background-image: url(../assets/images/privileges_bg.jpg);
        background-size: 100% auto;
        background-position: center top;
        background-repeat: no-repeat;
    }

    #privileges .container {
        max-width: 1400px;
    }

    #privileges h2 {
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        font-size: 48px;
        line-height: normal;
        margin: 0 auto;
        font-weight: 500;
        color: #8b1103;
    }

    #privileges p {
        font-size: 18px;
        line-height: normal;
        color: #555;
    }

    .privileges_row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .privileges_col {
        position: relative;
        width: 100%;
        padding: 20px 15px;
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        align-content: center;
    }

    #privileges .privileges_col p {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        margin: 0;
        padding: 0 30px 0 20px;
        font-size: 20px;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.78);
        max-width: calc(100% - 131px);
    }

    #privileges .privileges_col img {
        display: flow-root;
        width: 131px;
        height: 131px;
        margin: auto 0;
    }

    .privileges_tooth_up {
        width: 100%;
        height: 7px;
        margin: 0;
        padding: 0;
        background-image: url(../assets/images/tooth_bg_up.png);
        background-size: auto 7px;
        background-position: center center;
    }
    .privileges_tooth_down {
        width: 100%;
        height: 8px;
        margin: 0;
        padding: 0;
        background-image: url(../assets/images/tooth_bg_down.png);
        background-size: auto 8px;
        background-position: center center;
    }

    @media (max-width: 1200px) {
        #privileges .privileges_col p {
            font-size: 16px;
            max-width: calc(100% - 100px);
        }

        #privileges .privileges_col img {
            width: 100px;
            height: 100px;
            margin: auto 0;
        }

        #privileges {
            background-size: 120% auto;
        }
    }


    @media (max-width: 991px) {
        #privileges {
            background-size: 150% auto;
        }

        #privileges h2 {
            font-size: 40px;
        }

        #privileges p {
            font-size: 16px;
        }

        #privileges .privileges_col p {
            padding: 0 20px 0 15px;
            font-size: 14px;
        }
    }

    @media (max-width: 768px) {
        #privileges h2 {
            font-size: 36px;
        }

        #privileges p {
            font-size: 14px;
        }

        #privileges .privileges_col img {
            width: 60px;
            height: 60px;
            margin: auto 0;
        }

        #privileges .privileges_col p {
            font-size: 12px;
            max-width: calc(100% - 60px);
        }
    }

    @media (max-width: 576px) {
        #privileges h2 {
            font-size: 30px;
        }

        #privileges p {
            font-size: 12px;
        }

        #privileges {
            background-size: 200% auto;
        }

        .privileges_col {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 10px 30px
        }
    }

    @media (max-width: 450px) {
        #privileges {
            background-size: 250% auto;
        }
    }

    @media (max-width: 360px) {
        #privileges {
            background-size: 350% auto;
        }
    }
</style>
