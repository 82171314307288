export default {
    state: {
        loading: false,
        error: null,
        callBackCompleted: false,
        standardFormCompleted: false
    },
    mutations: {
        setLoading (state, payload) {
            state.loading = payload
        },
        setError (state, payload) {
            state.error = payload
        },
        clearError (state) {
            state.error = null
        },
        callBackFormCompleted (state) {
            state.callBackCompleted = true
        },
        standardFormCompleted (state) {
            state.standardFormCompleted = true
        },
    },
    actions: {
        setLoading ({ commit }, payload) {
            commit('setLoading', payload)
        },
        setError ({ commit }, payload) {
            commit('setError', payload)
        },
        clearError ({ commit }) {
            commit('clearError')
        },
        callBackFormCompleted ({ commit }) {
            commit('callBackFormCompleted')
        },
        standardFormCompleted ({ commit }) {
            commit('standardFormCompleted')
        },
    },
    getters: {
        loading (state) {
            return state.loading
        },
        error (state) {
            return state.error
        },
        getCallBackFormCompleted (state) {
            return state.callBackCompleted
        },
        getStandardFormCompleted (state) {
            return state.standardFormCompleted
        }
    }
}
