<template>
    <v-dialog
        v-model="showUserPopup"
        width="500"
        @click:outside="clickOutside"
    >
        <v-card class="elevation-12">
            <v-toolbar
                color="error"
                dark
            >
                <v-toolbar-title>Вы авторизованы</v-toolbar-title>
                <v-spacer />
                <v-btn
                    icon
                    @click="closeLoginPopup"
                >
                    <v-icon>fa-times</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="pt-4">
                <div class="text-h4">
                    <v-avatar
                        class="mr-2"
                        color="error"
                        size="36px"
                    >
                        <span class="white--text headline">{{ user.login.substr(0, 1).toUpperCase() }}</span>
                    </v-avatar>
                    <b>{{ user.login }}</b>
                    <p class="text-h6 text-center">
                        Авторизация действительна до<br><b class="black--text">{{ localeDate(user.expired) }}</b>
                    </p>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    class="px-6"
                    color="error"
                    medium
                    @click="onLogout"
                >
                    Выход из системы
                </v-btn>
                <v-spacer />
                <v-btn
                    class="px-6 button_close"
                    color="secondary"
                    medium
                    outlined
                    @click="closeLoginPopup"
                >
                    Отмена
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        showUserPopup: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        user () {
            return this.$store.getters.user
        },
        loading () {
            return this.$store.getters.loading
        }
    },
    methods: {
        clickOutside () {
            this.$emit('clickOutside', false)
        },
        closeLoginPopup () {
            this.$emit('clickOutside', false)
        },
        onLogout () {
            this.$store.dispatch('logoutUser')
            if (this.$router.currentRoute.path !== '/') this.$router.push('/');
        },
        localeDate(date) {
            return (new Date(date)).toLocaleString()
        }
    }
}
</script>

<style scoped>
@media (max-width: 430px) {
    .button_close {
        display: none;
    }
}
</style>
