<template>
    <section id="capabilities">
        <v-container class="py-15">
            <h2 class="d-block text-center pb-1 pt-5 mt-4">
                Наши Возможности
            </h2>
            <p class="d-block text-center mb-2 mb-sm-5">
                Пунктуальность и качество - Наш принцип
            </p>
            <v-row class="py-5">
                <v-col
                    cols="12"
                    md="6"
                    sm="8"
                >
                    <v-row class="text-center capabilities_block">
                        <v-col cols="6">
                            <img
                                alt=""
                                src="../assets/images/capability_ico_1.png"
                            >
                            <p>Полная автоматизированная система мобильных станций</p>
                        </v-col>
                        <v-col cols="6">
                            <img
                                alt=""
                                src="../assets/images/capability_ico_2.png"
                            >
                            <p>Всё оборудование сертифицировано, уникально и проходит по всем нормам пожарной безопасности</p>
                        </v-col>
                        <v-col cols="6">
                            <img
                                alt=""
                                src="../assets/images/capability_ico_3.png"
                            >
                            <p>Своя лаборатория</p>
                        </v-col>
                        <v-col cols="6">
                            <img
                                alt=""
                                src="../assets/images/capability_ico_4.png"
                            >
                            <p>Собственный автопарк</p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    class="car_container d-none d-sm-flex"
                    cols="4"
                    md="6"
                >
                    <img
                        class="animation_car"
                        src="../assets/images/mini_wv_1.png"
                    >
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    name: "HomeCapabilities"
}
</script>
background: url(../assets/images/bg_helper.jpg) center center / cover no-repeat ;
<style scoped>
    #capabilities {
        background: url(../assets/images/capability.jpg) center top /cover no-repeat;
        overflow-x: hidden;
    }

    #capabilities h2 {
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        font-size: 48px;
        line-height: normal;
        margin: 0 auto;
        font-weight: 500;
        color: #ffffff;
    }

    #capabilities p {
        font-size: 18px;
        line-height: normal;
        color: #ffffff;
    }

    .car_container {
        position: relative;
    }

    .animation_car {
        position: absolute;
        top: 0;
        left: 0;
    }

    #capabilities .capabilities_block div
    {
        padding: 20px 15px;
    }

    #capabilities .capabilities_block img
    {
        margin-bottom: 20px;
    }

    #capabilities .capabilities_block p
    {
        font-size: 20px;
    }

    @media (max-width: 1920px) {
        #capabilities .capabilities_block p
        {
            font-size: 18px;
        }
    }

    @media (max-width: 1200px) {
        #capabilities .capabilities_block p
        {
            font-size: 16px;
        }

        .animation_car {
            width: 120%;
            top: 15%;
        }
    }

    @media (max-width: 991px) {
        #capabilities .capabilities_block p
        {
            font-size: 14px;
        }
    }

    @media (max-width: 768px) {
        .animation_car {
            width: 200%;
            top: 40%;
        }
    }

    @media (max-width: 576px) {
        #capabilities .capabilities_block p
        {
            font-size: 16px;
        }

        #capabilities .capabilities_block img
        {
            margin-bottom: 10px;
        }

        #capabilities h2 {
            font-size: 40px;
        }

        #capabilities p {
            font-size: 16px;
        }
    }

    @media (max-width: 450px) {
        #capabilities h2 {
            font-size: 32px;
        }

        #capabilities p {
            font-size: 14px;
        }

        #capabilities .capabilities_block p
        {
            font-size: 15px;
        }
    }

    @media (max-width: 340px) {
        #capabilities .capabilities_block p
        {
            font-size: 14px;
        }
    }
</style>
