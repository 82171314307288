<template>
    <v-dialog
        v-model="showLoginPopup"
        width="500"
        @click:outside="clickOutside"
    >
        <v-card class="elevation-12">
            <v-toolbar
                color="error"
                dark
            >
                <v-toolbar-title>Авторизация</v-toolbar-title>
                <v-spacer />
                <v-btn
                    icon
                    @click="closeLoginPopup"
                >
                    <v-icon>fa-times</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="pt-4">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy
                >
                    <v-text-field
                        v-model="login"
                        label="Имя пользователя"
                        name="login"
                        prepend-icon="fa-user"
                        :rules="loginRules"
                        type="text"
                    />
                    <v-text-field
                        id="password"
                        v-model="password"
                        counter
                        label="Пароль"
                        name="password"
                        prepend-icon="fa-lock"
                        :rules="passwordRules"
                        type="password"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    class="px-6"
                    color="error"
                    :disabled="!valid || loading"
                    :loading="loading"
                    medium
                    @click="onSubmit"
                >
                    Вход
                </v-btn>
                <v-spacer />
                <v-btn
                    class="px-6"
                    color="secondary"
                    medium
                    outlined
                    @click="closeLoginPopup"
                >
                    Отмена
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        showLoginPopup: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            login: '',
            loginRules: [
                v => !!v || 'Введите имя пользователя!',
                v => /^([a-zA-Z0-9._@-]{4,20})$/.test(v) || 'Не корректное имя пользователя!'
            ],
            password: '',
            passwordRules: [
                v => !!v || 'Введите пароль!',
                v => (v && v.length >= 6) || 'Пароль не может быть меньше 6 символов!'
            ],
            valid: false
        }
    },
    computed: {
        loading () {
            return this.$store.getters.loading
        }
    },
    methods: {
        clickOutside () {
            this.$emit('clickOutside', false)
        },
        closeLoginPopup () {
            this.$emit('clickOutside', false)
        },
        onSubmit () {
            if (this.$refs.form.validate()) {
                const user = {
                    login: this.login,
                    password: this.password
                }

                this.$store.dispatch('loginUser', user)
                    .then(() => {
                        //if (this.$router.currentRoute.path !== '/') this.$router.push('/');
                    })
                    .catch(() => {})
            }
        }
    }
}
</script>

<style scoped>

</style>
