export default {
    state: {
        CallBackPopup: false
    },
    mutations: {
        showCallBackPopup(state) {
            state.CallBackPopup = true;
        },
        hideCallBackPopup(state) {
            state.CallBackPopup = false;
        }
    },
    actions: {
        showCallBackPopup ({ commit }) {
            commit('showCallBackPopup')
        },
        hideCallBackPopup({ commit }) {
            commit('hideCallBackPopup')
        }
    },
    getters: {
        getCallBackPopup (state) {
            return state.CallBackPopup;
        }
    }
}
