<template>
    <section id="how_work">
        <v-container>
            <h2 class="d-block text-center refuel_red pb-1">
                Как Это Работает?
            </h2>
            <p class="d-block text-center mb-12">
                4 шага от завода до Вашего бака
            </p>
            <v-row
                class="main_how_work text-center"
                justify="center"
            >
                <v-col
                    cols="6"
                    lg=""
                    sm="4"
                >
                    <h3>НПЗ</h3>
                    <img
                        alt=""
                        src="../assets/images/how_work_1.png"
                    >
                    <p>Мы закупаем топливо напрямую у завода производителя.</p>
                </v-col>
                <v-col
                    cols="6"
                    lg=""
                    sm="4"
                >
                    <h3>Бензовоз</h3>
                    <img
                        alt=""
                        src="../assets/images/how_work_2_1.png"
                    >
                    <p>После анализа качества, топливо направляется в заправочный пункт.</p>
                </v-col>
                <v-col
                    cols="6"
                    lg=""
                    sm="4"
                >
                    <h3>Топливозаправочный пункт</h3>
                    <img
                        alt=""
                        src="../assets/images/how_work_3_1.png"
                    >
                    <p>Наша лаборатория проводит экспрес тест топлива после чего оно отправляется в резервуар топливозаправочного пункта</p>
                </v-col>
                <v-col
                    cols="6"
                    lg=""
                    sm="4"
                >
                    <h3>Refuel</h3>
                    <img
                        alt=""
                        src="../assets/images/how_work_4_1.png"
                    >
                    <p>После заказа топлива, заправщик сразу же отправляется к Вам.</p>
                </v-col>
                <v-col
                    cols="6"
                    lg=""
                    sm="4"
                >
                    <h3>Ваш бак</h3>
                    <img
                        alt=""
                        src="../assets/images/how_work_5.png"
                    >
                    <p>В течении часа после заказа Вы получаете качественное топливо по выгодной цене.</p>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    name: "HomeHowWork"
}
</script>

<style scoped>
    #how_work {
        background-color: #F0F0F0;
    }

    #how_work h2 {
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        font-size: 48px;
        line-height: normal;
        margin: 0 auto;
        padding: 0 0 40px 0;
        font-weight: 500;
    }

    #how_work p {
        font-size: 18px;
        line-height: normal;
        color: #555;
    }

    #how_work .main_how_work div h3 {
        font-size: 18px;
        line-height: 18px;
        height: 36px;
        font-weight: 600;
        color: #000;
    }

    #how_work .main_how_work div img {
        width: 100%;
        padding-bottom: 10px;
    }

    #how_work .main_how_work div p {
        font-size: 18px;
        line-height: normal;
        color: #555;
    }

    @media (max-width: 1920px) {
        #how_work .main_how_work div p {
            font-size: 16px;
        }
    }

    @media (max-width: 768px) {
        #how_work .main_how_work div h3 {
            font-size: 16px;
            line-height: 16px;
            height: 32px;
        }
    }

    @media (max-width: 576px) {
        #how_work .main_how_work div p {
            font-size: 14px;
        }
        #how_work .main_how_work div h3 {
            font-size: 16px;
            line-height: 16px;
            height: 28px;
        }
        #how_work h2 {
            font-size: 40px;
        }
    }

    @media (max-width: 450px) {
        #how_work h2 {
            font-size: 36px;
        }
    }

    @media (max-width: 380px) {
        #how_work h2 {
            font-size: 32px;
        }
    }

    @media (max-width: 340px) {
        #how_work h2 {
            font-size: 28px;
        }
    }
</style>
