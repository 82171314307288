import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/faq',
        name: 'Faq',
        component: () => import('../views/Faq.vue')
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: () => import('../views/Contacts.vue')
    },
    {
        path: '/public',
        name: 'Public',
        component: () => import('../views/Public.vue')
    },
    {
        path: '/lk',
        name: 'LkMain',
        component: () => import('../views/LkMain.vue'),
    },
    {
        path: '/test',
        name: 'Test',
        component: () => import('../views/Test.vue'),
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior () {
        return { x: 0, y: 0 }
    }

})

router.beforeResolve((to, from, next) => {
    store.commit('setLoading', true)
    next()
})

router.afterEach(() => {
    store.commit('setLoading', false)
})

export default router
