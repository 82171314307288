<template>
    <div class="home">
        <app-home-panorama />
        <app-home-costs />
        <app-home-about />
        <app-white-to-grey-helper />
        <app-home-how-work />
        <app-home-capabilities />
        <app-home-privileges />
        <app-home-clients />
        <app-white-to-grey-helper />
        <app-home-partners />
        <div style="height: 1px;background-image: linear-gradient(90deg, rgba(60,60,60,0), rgba(60,60,60,1) 50%, rgba(60,60,60,0));" />
        <app-call-button />
    </div>
</template>

<script>

import Vue from "vue";
import HomePanorama from "../components/HomePanorama";
import HomeCosts from "../components/HomeCosts";
import HomeAbout from "../components/HomeAbout";
import WhiteToGreyHelper from "../components/WhiteToGreyHelper";
import HomeHowWork from "../components/HomeHowWork";
import HomeCapabilities from "../components/HomeCapabilities";
import HomePrivileges from "../components/HomePrivileges";
import HomeClients from "../components/HomeClients";
import HomePartners from "../components/HomePartners";
import CallButton from "../components/CallButton";

Vue.component('app-home-panorama', HomePanorama);
Vue.component('app-home-costs', HomeCosts);
Vue.component('app-home-about', HomeAbout)
Vue.component('app-white-to-grey-helper', WhiteToGreyHelper)
Vue.component('app-home-how-work', HomeHowWork)
Vue.component('app-home-capabilities', HomeCapabilities)
Vue.component('app-home-privileges', HomePrivileges)
Vue.component('app-home-clients', HomeClients)
Vue.component('app-home-partners', HomePartners)
Vue.component('app-call-button', CallButton)

export default {
    name: 'Home'
}
</script>

<style scoped>

</style>
