import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import moment from 'moment';
import './plugins/vuetify-mask.js';

import Header from './components/AppHeader';
import ModalCallBack from './components/ModalCallBack';
import Login_popup from './components/ModalLogin';
import User_popup from './components/ModalUser';
import ModalAgreement from './components/ModalAgreement';
import Footer from "./components/AppFooter";

Vue.config.productionTip = false
Vue.prototype.$moment = moment;

Vue.component('app-header', Header);
Vue.component('app-call-back', ModalCallBack);
Vue.component('app-login_popup', Login_popup);
Vue.component('app-user_popup', User_popup);
Vue.component('app-agreement_popup', ModalAgreement);
Vue.component('app-footer', Footer);

Vue.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        }
        window.addEventListener('scroll', f)
    }
})


new Vue({
    axios,
    router,
    store,
    vuetify,
    render: h => h(App),
    created () {
        if (localStorage.getItem('user')) {
            try {
                let user = JSON.parse(localStorage.getItem('user'));
                let now = new Date();
                let expired = new Date(user.expired);
                if (expired > now) {
                    this.$store.dispatch('autoLoginUser', user)
                } else {
                    localStorage.removeItem('user');
                }

            } catch(e) {
                localStorage.removeItem('user');
            }
        }

        this.$store.dispatch('updateCosts');
    }
}).$mount('#app')
