<template>
    <v-dialog
        v-model="showAgreement"
        width="800"
        @click:outside="clickOutside"
    >
        <v-card class="elevation-12">
            <v-toolbar
                color="error"
                dark
            >
                <v-toolbar-title>Согласие на обработку моих персональных данных</v-toolbar-title>
                <v-spacer />
                <v-btn
                    icon
                    @click="clickOutside"
                >
                    <v-icon>fa-times</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="pt-4">
                <p>
                    Предоставляя свои персональные данные Покупатель даёт согласие на обработку, хранение и использование своих персональных данных на основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. в следующих целях:
                </p>
                <ul>
                    <li>Регистрации Пользователя на сайте</li>
                    <li>Осуществление клиентской поддержки</li>
                    <li>Выполнение Продавцом обязательств перед Покупателем</li>
                    <li>Проведения аудита и прочих внутренних исследований с целью повышения качества предоставляемых услуг.</li>
                </ul>
                <p>
                    Под персональными данными подразумевается любая информация личного характера, позволяющая установить личность Покупателя такая как:
                </p>
                <ul>
                    <li>Фамилия, Имя, Отчество</li>
                    <li>Контактный телефон</li>
                    <li>Адрес электронной почты</li>
                </ul>
                <p>Персональные данные Покупателей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.</p>
                <p>Продавец обязуется не передавать полученные персональные данные третьим лицам, за исключением следующих случаев:</p>
                <ul>
                    <li>По запросам уполномоченных органов государственной власти РФ только по основаниям и в порядке, установленным законодательством РФ</li>
                    <li>Стратегическим партнерам, которые работают с Продавцом для предоставления продуктов и услуг, или тем из них, которые помогают Продавцу реализовывать продукты и услуги потребителям. Мы предоставляем третьим лицам минимальный объем персональных данных, необходимый только для оказания требуемой услуги или проведения необходимой транзакции.</li>
                </ul>
                <p>
                    Продавец оставляет за собой право вносить изменения в одностороннем порядке в настоящие правила, при условии, что изменения не противоречат действующему законодательству РФ. Изменения условий настоящих правил вступают в силу после их публикации на Сайте.
                </p>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    class="px-6"
                    color="error"
                    medium
                    @click="hideAndAccept"
                >
                    Принять
                </v-btn>
                <v-spacer />
                <v-btn
                    class="px-6"
                    color="secondary"
                    medium
                    outlined
                    @click="hideAndRefuse"
                >
                    Отказаться
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ModalAgreement",
    props: {
        showAgreement: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {

        }
    },
    methods: {
        clickOutside () {
            this.$emit('clickOutside', false)
        },
        closeLoginPopup () {
            this.$emit('clickOutside', false)
        },
        hideAndAccept () {
            this.$emit('hideAndAccept', true)
        },
        hideAndRefuse () {
            this.$emit('hideAndRefuse', true)
        }
    }
}
</script>

<style scoped>

</style>
