<template>
    <section id="about">
        <v-container class="pt-2">
            <h2 class="d-block text-center refuel_red">
                О Компании
            </h2>
            <v-row class="row main_about text-center pb-5">
                <v-col
                    class="py-2 py-sm-0"
                    cols="12"
                    sm="4"
                >
                    <transition name="fadeInUp1">
                        <img
                            v-if="showWithDelay"
                            alt=""
                            src="../assets/images/about_1.png"
                        >
                    </transition>
                    <p>Мы предоставляем сервис по заправке автотранспорта юридических и физических лиц на территории Москвы и Московской области.</p>
                </v-col>

                <v-col
                    class="py-2 py-sm-0"
                    cols="12"
                    sm="4"
                >
                    <transition name="fadeInUp2">
                        <img
                            v-if="showWithDelay"
                            alt=""
                            src="../assets/images/about_2.png"
                        >
                    </transition>
                    <p>В нашем автопарке новые автомобили Volkswagen, Peugeot и Citroen. В машинах установлено необходимое оборудование для быстрой и безопасной заправки Вашего авто, а также камеры для видеофиксации.</p>
                </v-col>

                <v-col
                    class="py-2 py-sm-0"
                    cols="12"
                    sm="4"
                >
                    <transition name="fadeInUp3">
                        <img
                            v-if="showWithDelay"
                            alt=""
                            class="wow fadeInUp"
                            src="../assets/images/about_3.png"
                        >
                    </transition>
                    <p>Наша система полностью автоматизирована, является уникальной, даёт прозрачную отчётность. Клиент имеет возможность получать отчёт в онлайн режиме за любой отчётный период, а именно: дату заправки, количество литров, номер автомобиля.</p>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    name: "HomeAbout",
    data () {
        return {
            showWithDelay: false
        }

    },
    mounted(){
        setTimeout(()=>{
            this.showWithDelay = true
        },700)
    }
}
</script>

<style scoped>
    .fadeInUp1-enter-active {
        animation: fadeInUp .4s;
    }
    .fadeInUp2-enter-active {
        animation: fadeInUp .8s;
    }
    .fadeInUp3-enter-active {
        animation: fadeInUp 1.2s;
    }
    #about h2 {
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        font-size: 48px;
        line-height: normal;
        margin: 0 auto;
        padding: 0 0 40px 0;
        font-weight: 500;
    }

    .main_about div p {
        font-size: 18px;
        max-width: 350px;
        margin: 15px auto 0 auto;
        line-height: normal;
        color: #555;
    }

    @media (max-width: 991px) {
        .main_about div p {
            font-size: 16px;
        }
        .main_about div {
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        .main_about div p {
            font-size: 14px;
        }
    }
    @media (max-width: 576px) {
        .main_about div p {
            margin: 5px auto 0 auto;
        }
        .main_about div img {
            width: 100px;
        }
        #about h2 {
            font-size: 40px;
            padding: 0 0 20px 0;
        }
    }
    @media (max-width: 450px) {
        #about h2 {
            font-size: 36px;
        }
    }

    @media (max-width: 380px) {
        #about h2 {
            font-size: 32px;
        }
    }

    @media (max-width: 340px) {
        #about h2 {
            font-size: 28px;
        }
    }
</style>
