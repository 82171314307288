<template>
    <section id="bg_helper" />
</template>

<script>
export default {
    name: "WhiteToGreyHelper"
}
</script>

<style scoped>
    #bg_helper {
        height: 0;
        background: url(../assets/images/bg_helper.jpg) center center / cover no-repeat ;
        width: 100%;
        padding-bottom: 4.43%;
    }
</style>
