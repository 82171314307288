<template>
    <section
        id="footer"
        class="text-right py-2 py-lg-4"
    >
        <v-container>
            <v-row>
                <v-col
                    class="py-2 py-lg-0 text-left"
                    cols="12"
                    lg="6"
                >
                    <img
                        alt="Refuel logo white"
                        src="../assets/images/logo_white.png"
                    >
                </v-col>
                <v-col
                    class="py-2 py-lg-0 text-right"
                    cols="12"
                    lg="6"
                >
                    <ul class="footer_links">
                        <li>
                            <router-link to="/">
                                ГЛАВНАЯ
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/faq/">
                                FAQ
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/contacts/">
                                КОНТАКТЫ
                            </router-link>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    name: "SiteFooter"
}
</script>

<style scoped>
    #footer {
        background-color: #333333;
    }

    #footer .container {
        max-width: 1400px;
    }

    .footer_links {
        margin-bottom: 0;
        padding: 0;
    }
    .footer_links li {
        display: inline-block;
    }

    .footer_links li a {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        padding: 5px 15px;
    }
    .footer_links li a:hover {
        text-decoration: underline;
    }

    @media (max-width: 400px) {
        .footer_links li a {
            font-size: 12px;
            font-weight: 500;
            padding: 5px 10px;
        }
    }
    @media (max-width: 315px) {
        .footer_links li a {
            font-size: 11px;
        }
    }

</style>
