import axios from 'axios'

export default {
    state: {
        costs: null
    },
    mutations: {
        updateCosts (state, payload) {
            state.costs = payload
        }
    },
    actions: {
        async updateCosts ({ commit }) {
            commit('clearError')
            commit('setLoading', true)

            try {
                axios.post(`/api/fuel2.php`)
                    .then(response => {
                        commit('setLoading', false);
                        commit('updateCosts', response.data)
                        //console.log(response.data)
                    }, error => {
                        commit('setLoading', false);
                        commit('setError', error.toJSON().message);
                    });
            } catch(e) {
                console.log(e)
                throw e
            }
        }
    },
    getters: {
        getCosts (state) {
            return state.costs
        }
    }
}
