<template>
    <section id="clients">
        <v-container>
            <h2 class="d-block text-center refuel_red pb-1 pt-5">
                Наши Довольные Клиенты
            </h2>
            <p class="d-block text-center pb-7">
                Наши клиенты экономят от 2% цены от стационарных АЗС
            </p>
            <v-row class="pb-3 pb-lg-5">
                <v-col
                    class="pt-3 pt-lg-0"
                    cols="6"
                    lg="4"
                >
                    <img
                        alt="МосГорТранс - клиент Refuel"
                        src="../assets/images/client_1.jpg"
                    >
                </v-col>
                <v-col
                    class="pt-3 pt-lg-0"
                    cols="6"
                    lg="4"
                >
                    <img
                        alt="МосТрансАвто - наш клиент"
                        src="../assets/images/client_2.jpg"
                    >
                </v-col>
                <v-col
                    class="py-3 py-sm-5 py-lg-0"
                    cols="12"
                    lg="4"
                >
                    <div class="panorama_head">
                        <div class="panorama_inner">
                            <h3>Наша цель</h3>
                        </div>
                    </div>
                    <p>
                        Обеспечить клиента топливом высокого качества и обслуживание парка транспорта любого размера на высоком Европейском уровне.
                        <br><br>
                        Предоставить систему полной автоматизации процесса обслуживания, с полным контролем всех расходов.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    name: "HomeClients"
}
</script>

<style scoped>
    #clients {
        background-color: #FFFFFF;
    }

    #clients .container {
        max-width: 1400px;
    }

    #clients h2 {
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        font-size: 48px;
        line-height: normal;
        margin: 0 auto;
        font-weight: 500;
    }

    #clients p {
        font-size: 18px;
        line-height: normal;
        color: #555;
    }

    #clients .panorama_head {
        display: inline-block;
        margin-bottom: 30px;
    }

    .panorama_head {
        background-image: linear-gradient(to right, #901604, #b03405);
        transform: skewX(-10deg);
        transform-origin: 0 100%;
        border-radius: 10px;
    }

    .panorama_inner h3 {
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        font-size: 26px;
        line-height: normal;
        font-weight: normal;
        padding: 5px 25px;
        margin: 0;
        color: #fff;
        transform: skewX(10deg);
    }

    #clients .row div img {
        width: 100%;
        height: auto;
        max-width: 396px;
        margin: 0 auto;
    }

    #clients .row div:last-child {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        text-align: right;
        justify-content: flex-end;
    }

    @media (max-width: 991px) {
        #clients .row div:last-child {
            text-align: center;
            justify-content: center;
        }

        #clients h2 {
            font-size: 40px;
        }
    }

    @media (max-width: 768px) {
        #clients h2 {
            font-size: 32px;
        }
    }

    @media (max-width: 576px) {
        #clients h2 {
            font-size: 24px;
        }

        #clients p {
            font-size: 16px;
        }
    }
</style>
