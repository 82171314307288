<template>
    <section id="call_button">
        <a
            @click="openCallBack"
        >
            <span>
                Свяжитесь<br>с нами
            </span>
        </a>
    </section>
</template>

<script>
export default {
    name: "CallButton",
    methods: {
        openCallBack () {
            this.$store.dispatch('showCallBackPopup')
        }
    }
}
</script>

<style scoped>
    #call_button {
        position: relative;
        height: 0;
        padding-bottom: 28.85%;
        background-image: url(../assets/images/call_us_bg_1.jpg);
        background-size: 100% 100%;
        background-position: center;
    }

    #call_button a {
        width: 11.54%;
        height: 40%;
        border-radius: 50%;
        overflow: auto;
        margin: auto;
        position: absolute;
        border: 1px solid rgb(203, 82, 44);
        top: 0; left: 0; bottom: 0; right: 0;
        background-image: -moz-linear-gradient( -158deg, rgb(176,51,5) 0%, rgb(144,22,4) 100%);
        background-image: -webkit-linear-gradient( -158deg, rgb(176,51,5) 0%, rgb(144,22,4) 100%);
        background-image: -ms-linear-gradient( -158deg, rgb(176,51,5) 0%, rgb(144,22,4) 100%);
        background-image: linear-gradient( -158deg, rgb(176,51,5) 0%, rgb(144,22,4) 100%);
        box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.56);
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        flex-wrap: wrap;
        text-align: center;
        align-content: center;
    }

    #call_button a:hover {
        background-image: -moz-linear-gradient( -158deg, rgb(196, 52, 5) 0%, rgb(164, 22, 4) 100%);
        background-image: -webkit-linear-gradient( -158deg, rgb(196, 52, 5) 0%, rgb(164, 22, 4) 100%);
        background-image: -ms-linear-gradient( -158deg, rgb(196, 52, 5) 0%, rgb(164, 22, 4) 100%);
        background-image: linear-gradient( -158deg, rgb(196, 52, 5) 0%, rgb(164, 22, 4) 100%);
        box-shadow: 0 0 13px 0 rgba(255, 255, 255, 0.56);

    }

    #call_button a span {
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        font-size: 28px;
        line-height: normal;
        font-weight: 500;
        color: #FFFFFF;
    }




    #call_button a:hover span {
        /*text-shadow: 1px 1px rgba(255,255,255,0.5);*/
        font-size: 27px;
    }



    @media (max-width: 1600px) {
        #call_button a span {
            font-size: 24px;
        }
        #call_button a:hover span {
            font-size: 24px;
        }
    }
    @media (max-width: 1400px) {
        #call_button a span {
            font-size: 22px;
        }
        #call_button a:hover span {
            font-size: 22px;
        }
    }
    @media (max-width: 1300px) {
        #call_button a span {
            font-size: 20px;
        }
        #call_button a:hover span {
            font-size: 20px;
        }
    }

    @media (max-width: 1200px) {
        #call_button {
            background-size: 120% 120%;
        }
        #call_button a {
            width: 14.43%;
            height: 50%;
        }
    }

    @media (max-width: 991px) {
        #call_button a span {
            font-size: 18px;
        }
        #call_button a:hover span {
            font-size: 18px;
        }
    }

    @media (max-width: 800px) {
        #call_button {
            background-size: cover;
            height: 250px;
            padding: 0;
        }
        #call_button a {
            width: 120px;
            height: 120px;
        }
        #call_button a span {
            font-size: 16px;
        }
        #call_button a:hover span {
            font-size: 16px;
        }
    }
</style>
