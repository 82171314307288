<template>
    <section id="panorama">
        <img
            alt="Refuel cars"
            class="panorama_bg"
            src="../assets/images/refuel_main_1.jpg"
        >
        <transition name="fadeInLeft">
            <img
                v-if="showWithDelay"
                alt=""
                class="panorama_tree wow "
                src="../assets/images/tree.png"
            >
        </transition>
        <transition name="fadeInUp">
            <img
                v-if="showWithDelay"
                alt=""
                class="panorama_leaf_big"
                src="../assets/images/leaf_big.png"
            >
        </transition>
        <transition name="fadeInDown">
            <img
                v-if="showWithDelay"
                alt=""
                class="panorama_leaf_small"
                src="../assets/images/leaf_small.png"
            >
        </transition>
        <transition name="fadeInRight">
            <div
                v-if="showWithDelay"
                class="panorama_containeer"
            >
                <div class="panorama_head">
                    <div class="panorama_inner">
                        <h1>Доставим топливо в Ваш бак</h1>
                    </div>
                </div>
            </div>
        </transition>
    </section>
</template>

<script>
export default {
    data () {
        return {
            showWithDelay: false
        }
    },
    mounted(){
        setTimeout(()=>{
            this.showWithDelay = true
        },500)
    }
}
</script>

<style scoped>
    .fadeInRight-enter-active {
        animation: fadeInRight .5s;
    }

    .fadeInLeft-enter-active {
        animation: fadeInLeft .6s;
    }

    .fadeInDown-enter-active {
        animation: fadeInDown .7s;
    }

    .fadeInUp-enter-active {
        animation: fadeInUp .8s;
    }

    #panorama {
        position: relative;
        overflow: hidden;
    }

    .panorama_bg {
        width: 100%;
        height: auto;
    }

    .panorama_tree,
    .panorama_leaf_big,
    .panorama_leaf_small {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
    }

    .panorama_tree {
        z-index: 1;
    }

    .panorama_leaf_big {
        z-index: 1;
    }

    .panorama_leaf_small {
        z-index: 1;
    }

    .panorama_containeer {
        position: absolute;
        top: 7%;
        right: 25%;
        z-index: 1;
    }

    .panorama_head {
        background-image: linear-gradient(to right, #901604, #b03405);
        transform: skewX(-10deg);
        transform-origin: 0 100%;
        border-radius: 10px;
    }

    .panorama_inner h1 {
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        font-size: 26px;
        font-weight: normal;
        line-height: normal;
        padding: 5px 25px;
        margin: 0;
        color: #fff;
        transform: skewX(10deg);
    }

    @media (max-width: 1500px) {
        .panorama_inner h1 {
            font-size: 20px;
        }
    }
    @media (max-width: 1400px) {
        .panorama_inner h1 {
            font-size: 18px;
        }
    }
    @media (max-width: 1300px) {
        .panorama_inner h1 {
            font-size: 16px;
        }
    }
    @media (max-width: 1200px) {
        .panorama_inner h1 {
            font-size: 15px;
        }
    }
    @media (max-width: 1100px) {
        .panorama_inner h1 {
            font-size: 14px;
            padding: 5px 20px;
        }
        .panorama_head {
            border-radius: 7px;
        }
    }
    @media (max-width: 991px) {
        .panorama_inner h1 {
            font-size: 13px;
        }
    }
    @media (max-width: 900px) {
        .panorama_containeer {
            top: 10%;
            right: 20%;
        }
        .panorama_inner h1 {
            font-size: 12px;
        }
    }
    @media (max-width: 800px) {
        .panorama_inner h1 {
            font-size: 11px;
            padding: 3px 12px 5px 12px;
        }
        .panorama_head {
            border-radius: 5px;
        }
    }
    @media (max-width: 700px) {
        .panorama_inner h1 {
            font-size: 10px;
            padding: 3px 12px 4px 12px;
        }
    }
    @media (max-width: 600px) {
        .panorama_inner h1 {
            font-size: 9px;
        }
    }
    @media (max-width: 550px) {
        .panorama_inner h1 {
            font-size: 8px;
        }
    }
    @media (max-width: 500px) {
        .panorama_inner h1 {
            font-size: 7px;
        }
    }
    @media (max-width: 450px) {
        .panorama_inner h1 {
            font-size: 6px;
        }
        .panorama_containeer {
            top: 8%;
            right: 20%;
        }
    }
</style>
