<template>
    <v-app>
        <app-header
            :show-login-popup.sync="showLoginPopup"
            :show-user-popup.sync="showUserPopup"
            @loginPopupChanged="showLoginPopup = $event"
            @userPopupChanged="showUserPopup = $event"
        />
        <v-progress-linear
            :active="loading || localLoading"
            bottom
            color="red darken-4"
            fixed
            :indeterminate="loading || localLoading"
        />

        <router-view />

        <app-login_popup
            v-if="!isUserLoggedIn"
            :show-login-popup.sync="showLoginPopup"
            @clickOutside="showLoginPopup = $event"
        />
        <app-user_popup
            v-if="isUserLoggedIn"
            :show-user-popup.sync="showUserPopup"
            @clickOutside="showUserPopup = $event"
        />

        <template v-if="error">
            <v-snackbar
                color="error"
                elevation="10"
                timeout="5000"
                :value="true"
                @input="closeError"
            >
                {{ error }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="closeError"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </template>

        <app-call-back />
        <app-footer />
    </v-app>
</template>

<script>
export default {
    data () {
        return {
            showLoginPopup: false,
            showUserPopup: false,
            localLoading:false
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        error () {
            return this.$store.getters.error
        },
        user () {
            return this.$store.getters.user
        },
        isUserLoggedIn () {
            return this.$store.getters.isUserLoggedIn
        },
        loginError () {
            return this.$store.getters.getLoginError
        }
    },
    methods: {
        closeError () {
            this.$store.dispatch('clearError')
        }
    },
    updated() {
        if (this.loginError === true) {
            this.showLoginPopup = true
            this.$store.dispatch('setLoginError', false)
        }
    }
}
</script>

<style>
    @import url(assets/fonts/RobotoSlab/index.css);
    @import url(assets/fonts/Roboto/index.css);
    body {
        font-family:"Roboto",Helvetica,Arial,sans-serif;
        background-color: #ffffff;
        overflow-x: hidden;
    }

    .f-115 {
        font-size:1.15rem;
    }
    .f-125 {
        font-size:1.25rem;
    }
    .f-150 {
        font-size:1.50rem;
    }
    .f-175 {
        font-size:1.75rem;
    }
    .f-200 {
        font-size:2rem;
    }

    .c-09 {
        color: rgba(0,0,0,.9);
    }

    .w-300 {
        width: 300px;
    }

    .refuel_red {
        color: #8b1103;
    }

    .refuel_white {
        color: #f0f0f0;
    }

    .refuel_grey {
        color: #333333;
    }

    @keyframes fadeInRight {
        0% {
            opacity:0;
            -webkit-transform:translate3d(100%,0,0);
            transform:translate3d(100%,0,0)
        }
        100% {
            opacity:1;
            -webkit-transform:translate3d(0,0,0);
            transform:translate3d(0,0,0)
        }
    }

    @keyframes fadeInLeft {
        0% {
            opacity:0;
            -webkit-transform:translate3d(-100%,0,0);
            transform:translate3d(-100%,0,0)
        }
        100% {
            opacity:1;
            -webkit-transform:translate3d(0,0,0);
            transform:translate3d(0,0,0)
        }
    }

    @keyframes fadeInDown {
        0% {
            opacity:0;
            -webkit-transform:translate3d(0,-100%,0);
            transform:translate3d(0,-100%,0)
        }
        100% {
            opacity:1;
            -webkit-transform:translate3d(0,0,0);
            transform:translate3d(0,0,0)
        }
    }

    @keyframes fadeInUp {
        0% {
            opacity:0;
            -webkit-transform:translate3d(0,100%,0);
            transform:translate3d(0,100%,0)
        }
        100% {
            opacity:1;
            -webkit-transform:translate3d(0,0,0);
            transform:translate3d(0,0,0)
        }
    }

    @keyframes swing {
        20% {
            transform: rotate3d(0, 0, 1, 15deg);
        }

        40% {
            transform: rotate3d(0, 0, 1, -10deg);
        }

        60% {
            transform: rotate3d(0, 0, 1, 5deg);
        }

        80% {
            transform: rotate3d(0, 0, 1, -5deg);
        }

        100% {
            transform: rotate3d(0, 0, 1, 0deg);
        }
    }
</style>
