<template>
    <section id="modal_call_back">
        <v-dialog
            v-model="showCallBackPopup"
            width="500"
            @click:outside="closeCallBack"
        >
            <v-card class="elevation-12">
                <v-toolbar
                    color="error"
                    dark
                >
                    <v-toolbar-title>Мы Вам перезвоним</v-toolbar-title>
                    <v-spacer />
                    <v-btn
                        icon
                        @click="closeCallBack"
                    >
                        <v-icon>fa-times</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text v-if="callBackCompleted">
                    <div class="form_complete">
                        <v-icon
                            class="py-5 mx-auto"
                            color="green"
                        >
                            fa-check-circle
                        </v-icon>
                        <p>Форма заполнена!<br>Мы получили Ваше обращение!<br>Спасибо!</p>
                    </div>
                </v-card-text>
                <v-card-text
                    v-else
                    class="pt-4"
                >
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy
                    >
                        <v-text-field
                            v-model="name"
                            label="Ваше имя"
                            name="name"
                            prepend-icon="fa-user"
                            type="text"
                        />
                        <v-text-field-integer
                            v-model="phone"
                            label="Ваш телефон"
                            name="phone"
                            :options="{
                                inputMask: '(###) ###-##-##',
                                outputMask: '##########',
                                empty: null,
                                applyAfter: false,
                                alphanumeric: true,
                                lowerCase: false,
                            }"
                            :properties="{
                                prefix: mask,
                                suffix: '',
                                'prepend-icon': 'fa-phone',
                                rules: [
                                    v => !!v || 'Укажите пожалуйста Ваш телефон',
                                    v => (v !== null && v.length >= 15) || 'Слишком короткий номер телефона'
                                ],
                                readonly: false,
                                disabled: false,
                                outlined: false,
                                clearable: true,
                                placeholder: '',
                            }"
                            type="number"
                            @blur="checkBlur"
                            @focus="mask = '+7'"
                        />
                        <v-checkbox v-model="agree">
                            <template v-slot:label>
                                <div>
                                    Я даю согласие на
                                    <a
                                        @click.stop.prevent="showAgreement = true"
                                    >
                                        обработку моих персональных данных
                                    </a>
                                </div>
                            </template>
                        </v-checkbox>
                        <vue-recaptcha
                            ref="recaptcha"
                            :sitekey="sitekey"
                            size="invisible"
                            @expired="onCaptchaExpired"
                            @verify="register"
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        class="px-6"
                        color="error"
                        :disabled="!valid || loading || !agree"
                        :hidden="callBackCompleted"
                        :loading="loading"
                        medium
                        @click="onSubmit"
                    >
                        Отправить
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        class="px-6"
                        color="secondary"
                        medium
                        outlined
                        @click="closeCallBack"
                    >
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <app-agreement_popup
            :show-agreement.sync="showAgreement"
            @clickOutside="showAgreement = $event"
            @hideAndAccept="hideAndAccept"
            @hideAndRefuse="hideAndRefuse"
        />
    </section>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'

export default {
    components: { VueRecaptcha },
    data () {
        return {
            name: null,
            phone: null,
            focus: false,
            mask: '',
            valid: false,
            agree: true,
            showAgreement: false,
            sitekey: '6LdnJrkUAAAAAKs_SCg0_w_QDzHUxWvuE2M-UMFB'
        }
    },
    computed: {
        loading () {
            return this.$store.getters.loading
        },
        showCallBackPopup () {
            return this.$store.getters.getCallBackPopup
        },
        callBackCompleted () {
            return this.$store.getters.getCallBackFormCompleted
        }
    },
    methods: {
        closeCallBack () {
            this.$store.dispatch('hideCallBackPopup')
        },
        checkBlur () {
            console.log('blur - ' + this.phone)
            if (this.phone === null || this.phone === '') {
                this.mask = ''
            }
        },
        hideAndAccept () {
            this.agree = true
            this.showAgreement = false
        },
        hideAndRefuse () {
            this.agree = false
            this.showAgreement = false
        },
        onSubmit() {
            console.log('Pressed button')
            this.$refs.recaptcha.execute()
        },
        onCaptchaExpired () {
            this.$refs.recaptcha.reset()
        },
        register (recaptchaToken) {
            this.$store.dispatch('clearError')
            this.$store.dispatch('setLoading', true)
            try {
                axios.post('/form.php', {
                    name: this.name,
                    phone: '+7' + this.phone,
                    response: recaptchaToken,
                    form_id: 1,
                    page: this.$route.path
                })
                    .then(response =>{
                        console.log(response)
                        this.$store.dispatch('setLoading', false)
                        if (response.data.result === 'success') {
                            this.$store.dispatch('callBackFormCompleted')
                        } else {
                            this.$store.dispatch('setError', response.data.reason)
                        }
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('setLoading', false)
                    })
            } catch (error) {
                console.log('error - ' + error)
                this.$store.dispatch('setLoading', false)
                this.$store.dispatch('setError', error.message)
                throw error
            }
        }
    }
}
</script>

<style scoped>
    .form_complete {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .form_complete i {
        font-size: 50px;
    }
    .form_complete p {
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
    }
</style>
